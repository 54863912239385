import React, { lazy, Suspense } from 'react';

import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const TournamentsReport = lazy(() => import('src/components/tournaments-report/tournaments-report'));

const isBrowser = typeof window !== 'undefined';

const TournamentsReportPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Tournaments Report" />
      {isBrowser && (
        <Suspense fallback={<div />}>
          <TournamentsReport />
        </Suspense>
      )}
    </Layout>
  );
};

export default TournamentsReportPage;
